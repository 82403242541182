* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.header {
  margin : 2rem;
  padding : 2rem;
} 

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 5rem;
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

p {
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 1rem;
}

ul {
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
  margin : 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}
